import { template as template_c91c7bae1bea4a76a6027a68de879271 } from "@ember/template-compiler";
const FKText = template_c91c7bae1bea4a76a6027a68de879271(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
