import { template as template_0200352b7c4f416d9b6f1eb945613e7b } from "@ember/template-compiler";
const FKLabel = template_0200352b7c4f416d9b6f1eb945613e7b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
